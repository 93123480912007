import React, { useState, useEffect } from 'react';

import { useLocation, Link, useParams, useHistory } from 'react-router-dom';
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  SimpleGrid,
  Spacer,
  Center,

} from '@chakra-ui/react';
import '../css/datasetdetail_text.css';
import '../css/similar_datasets.css';
import axios from 'axios';
import { getApprovedDatasets } from '../pages/firebase_functions';


const SimilarDatasets_Section = (state) => {
  const location = useLocation();
  const dataset= state.dataset;
  const [isOpen, setIsOpen] = useState(false);
  const [similarDatasets, setSimilarDatasets] = useState([]);

  const [initialList, setInitialList] = useState([]);
  const [datasetList, setDatasetList] = useState([]);

  //GET APPROVED DATASETS
  useEffect(() => {
    // console.log("getting similar datasets...");
    //get datasets that match this dataset's topic excluding this dataset
    getApprovedDatasets(setSimilarDatasets, undefined, "topic", dataset.topic, dataset.dataset_name);
    //TEST get all datasets:
    // getApprovedDatasets(setSimilarDatasets, undefined);

  }, [dataset]);

  console.log("Length: ", similarDatasets);

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    
    <Box
      height="300px"
      maxW="1000px"
      p="20px"
      mt="20px">

      <Box> {
        similarDatasets.length > 0 && (
          // <Text fontSize="2xl" ml={{ base: '-200px', '2xl': "-250px" }} mt="20px" as="b">
          <Text className='super_header'>
            Similar Datasets
          </Text>
        )
      }
      </Box>

      <Box
        overflow-wrap //overflow="hidden"
        overflowX="scroll"
      >

        {/* <SimpleGrid columns={2} spacing={10} w="80vw" ml={{ base: '-200px', '2xl': "-250px" }} mt="-20px"> */}
        <div style={{ display: 'flex', overflowX: 'auto', width: '100vw' }}>

          {similarDatasets.map((dataset, index) => (
            <Box
              borderWidth="2px"
              borderRadius="lg"
              // overflow="scroll"
              boxShadow="none"
              p="10px"
              mt="15px"
              mr="20px"
              minW={"250px"}
              w="250px"
              h="320px"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <Image
                    border={"1px"}
                    borderColor={'lightgray'}
                    src={dataset.image_url}
                    alt="Image not Available"
                    w={"95px"}
                    h={"90px"}
                  />
                </div>
                <div className="mini-row-20">
                  <p className='similardts_title'>
                    {dataset.dataset_name}</p>
                </div>
                <div className="mini-row-50">
                  <p className='dd_text'>
                    {dataset.data_type} | {dataset.topic} | {dataset.phases} </p>
                </div>
                <div className="mini-row-65" >
                  <p className='dd_text'>
                    <b>ML Task Type:</b> {dataset.task_type_str}</p>
                </div>
                <div className="mini-row-65">
                  <div className="centered-obj">
                    <Link to={`/detail/${dataset.slug}`} state={dataset} onClick={scrollToTop}>
                      <Button bg="#7AAC35" color="#FFFFFF" variant="solid">
                        Learn More
                      </Button>
                    </Link>
                  </div>

                </div>
              </div>
            </Box>
          ))}
        </div>
        {/* </SimpleGrid> */}
      </Box>
      <br />
      <br />
    </Box>

  )
};

export default SimilarDatasets_Section;



