import { db, storage } from './firebase';
import {
    doc,
    setDoc,
    collection,
    serverTimestamp,
    onSnapshot,
    query, where, getDocs, deleteDoc,
    writeBatch, 
} from 'firebase/firestore';
import {
    ref,
    getDownloadURL
} from 'firebase/storage';

const slugify = require('slugify');
const datasetCollRef = collection(db, 'nadbenchmark');
const contactCollRef = collection(db, 'contact-messages');

//ADD DATASET DOCUMENT
export async function submitDataset(datasetInfo) {
  //TODO: secure dataset_file name, input image url of dataset, join task type into string

  //add slug, timestamp, ,
  datasetInfo.slug = slugify(datasetInfo.paper_title);
  datasetInfo.timestamp= serverTimestamp();
  // console.log(datasetInfo);
  // console.log(slugify(datasetInfo.paper_title));

  const datasetRef = doc(datasetCollRef);
  await setDoc(datasetRef, datasetInfo);
}

//ADD CONTACT-MESSAGE DOCUMENT
export async function submitContact(contactInfo) {
  //add timestamp,
  contactInfo.timestamp= serverTimestamp();
  // console.log(contactInfo);
  const contactMessageRef = doc(contactCollRef);
  await setDoc(contactMessageRef, contactInfo);
}

//GET LIST OF APPROVED DATASETS (REALTIME GET FUNCTION)
export function getApprovedDatasets(setDataList, setInitial, 
                                    query_field="approved", query_status="true",
                                    exclude_dataset=undefined){
    const q = query(
        datasetCollRef,
        where(query_field, '==', query_status),
        ...(exclude_dataset ? [where('dataset_name', '!=', exclude_dataset)] : [])
        //  where('owner', '==', currentUserId),
        //  where('score', '<=', 100) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
        // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
        // limit(1)
      );
  
      const unsub = onSnapshot(q, (querySnapshot) => {
      // const unsub = onSnapshot(datasetCollRef, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setDataList(items);
        if(setInitial) {setInitial(items);}
      });
      return () => {
        unsub();
      };
}

//GET MAIN IMAGE URL OF GIVEN FILENAME
export function getMainImageURL(filename) {
    const filepath = "main_images/" + filename;
    const mainImageRef = ref(storage, filepath);
    console.log(getDownloadURL(mainImageRef));
    return getDownloadURL(mainImageRef);
}

// DELETE DOC
// const deleteDocuments = async () => {
//     const querySnapshot = await getDocs(query(datasetCollRef, where('approved', '==', 'false')));
  
//     const batch = writeBatch(db);
  
//     querySnapshot.forEach((doc) => {
//       batch.delete(doc.ref);
//     });
  
//     await batch.commit();
  
//     console.log('Documents deleted successfully.');
//   };
  
//   deleteDocuments();
  