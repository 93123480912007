import { Box, Text, Image, Button, SimpleGrid, Flex, useToast, Spacer } from '@chakra-ui/react';
import React from 'react';
import nadb_logo from '../img/logo.png';
import rochci_logo from '../img/logo_hci_roc.png';
import ur_logo from '../img/logo_ur.png';
import poster_image from '../img/poster_image.png';
import poster from '../img/NaDBenchmarks2.pdf';


import paper from '../img/paper.png';
import '../css/about.css';


const reference = 'Proma, A. M., Islam, M. S., Ciko, S., Baten, R. A., & Hoque, E. (2022). NADBenchmarks-a compilation of Benchmark Datasets for Machine Learning Tasks related to Natural Disasters.';
const poster_reference = "Stela Ciko. NADBenchmarks 2: A Web Platform for Benchmark Datasets for Machine Learning Tasks related to Natural Disasters. MIT IEEE Undergraduate Research Technology Conference, September 2022, Cambridge, MA."

const centerStyle = {
  height: '100vh',
  display: 'grid',
  gridTemplateRows: ' auto auto auto',
  // gridTemplateColumns: '1fr',
  // gap: '20px',
};

const grid_1f_4f = {
  display: 'grid',
  gridTemplateColumns: '1fr 4fr', // First column takes 1/5th, second column takes 4/5th
  gap: '10px', // Add any desired gap between the columns
};

const grid_1f_7f = {
  display: 'grid',
  gridTemplateColumns: '1fr 7fr', // First column takes 1/5th, second column takes 4/5th
  gap: '10px', // Add any desired gap between the columns
};
const grid_1f_2f_4f = {
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 4fr', // First column takes 1/5th, second column takes 4/5th
  gap: '10px', // Add any desired gap between the columns
};

const rows_1f_1f = {
  display: 'grid',
  gridTemplateRows: '1fr 1fr', // First column takes 1/5th, second column takes 4/5th
  gap: '10px', // Add any desired gap between the columns
};

const About = () => {
  const toast = useToast();

  const displayToast = () => {
    toast({
      title: 'Reference copied.',
      description: reference,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    navigator.clipboard.writeText(reference);
  }

  const displayPosterToast = () => {
    toast({
      title: 'Reference copied.',
      description: poster_reference,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    navigator.clipboard.writeText(poster_reference);
  }


  const openReference = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      mx="auto"
      mt={{ base: '70px', '2xl': "90px" }}
      paddingLeft={{ base: '100px', '2xl': "200px" }}
      paddingRight={{ base: '100px', '2xl': "200px" }}
      // ml={{ base: '120px', '2xl': "350px" }}
      maxW="80%"
    >
      <Flex justifyContent="center" marginBottom={"70px"}>
        <div>
          <Text fontSize="2xl" mt="30px" color="#7AAC35" as="b" textAlign="center" >
            A Database for Benchmarks related to Natural Disasters
          </Text>
        </div>
      </Flex>

      <div>
        <div textAlign="justify"
          mt={{ base: '50px', '2xl': "100px" }}
        >
          <div style={grid_1f_4f}>
            <div width="50%">
              <Image
                // mx="auto"
                src={nadb_logo}
                mb='15px'
              />
            </div>

            <div>
              <br />
              <Text>
                NaDBenchmark hopes to facilitate the process of searching for natural disaster datasets for ML engineers.
              </Text>
              <br />
              <Text>
                NaDBenchmark presents a collection of existing benchmark datasets for machine learning models for natural disasters.
                The supported features and dataset information provided are specifically included as per feedback collected through
                user interviews.
              </Text>
              <br />
            </div>
          </div>
        </div>
        <br />
        <div style={grid_1f_4f}>
          <div>
          </div>

          <div style={grid_1f_7f}>
            <div>
              <Image
                src={rochci_logo}
                width={'90%'}
                onClick={() => openReference("https://roc-hci.com/")}
              />
            </div>
            <div>
              <Text className='about_text'>
                This project is supported by the
                <b><a href="https://roc-hci.com/" target="_blank" rel="noopener noreferrer">  ROC HCI </a></b>
                group of the University of Rochester.
              </Text>
            </div>
          </div>
        </div>
      </div>

      {/* PUBLICATIONS */}
      <div>
        <div>
          <Flex marginTop={"110px"} marginBottom={"15px"}>
            <Text className='about_header'>
              Publications
            </Text>
          </Flex>
        </div>

        <div textAlign="justify"
          mt={{ base: '80px', '2xl': "100px" }}
        >
          <div style={grid_1f_4f}>
            <Image
              // ml="100px"
              src={paper}
              width={"140px"}
              border={'solid'}
              mt="10px"
              onClick={() => openReference("https://www.climatechange.ai/papers/aaaifss2022/9")}
            />

            <Flex>
              <div style={rows_1f_1f}>
                <Text
                  className='about_text'
                  paddingTop={"10px"}
                >
                  <a href="https://www.climatechange.ai/papers/aaaifss2022/9" target="_blank" rel="noopener noreferrer">
                    A. Proma, M. S. Islam, S. Ciko, R. A. Baten, E. Hoque. NADBenchmarks – a compilation of Benchmark Datasets for Machine Learning Tasks related to Natural Disasters. The Role of AI in Responding to Climate Change, AAAI Fall Symposium Series, November 2022
                  </a>
                </Text>
                <div style={grid_1f_2f_4f}>
                  <Button
                    onClick={() => openReference("https://www.climatechange.ai/papers/aaaifss2022/9")}
                    bg="#7AAC35" color="#FFFFFF" variant="solid" mb="10px">
                    View
                  </Button>
                  <Button bg="#7AAC35" color="#FFFFFF" variant="solid" mb="10px" onClick={displayToast}>
                    Copy Reference
                  </Button>
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </div>

      {/* MORE RESOURCES */}
      <div>
        <div>
          <Flex marginTop={"60px"} marginBottom={"15px"}>
            <Text className='about_header'>
              More Resources
            </Text>
          </Flex>
        </div>

        <div textAlign="justify"
          mt={{ base: '80px', '2xl': "100px" }}
        >
          <div style={grid_1f_4f}>
            <Image
              // ml="100px"
              src={poster_image}
              width={"140px"}
              border={'solid'}
              mt="10px"
              onClick={() => openReference(poster)}
            />

            <Flex>
              <div style={rows_1f_1f}>
                <Text
                  className='about_text'
                  paddingTop={"10px"}
                >
                  Stela Ciko. NADBenchmarks 2: A Web Platform for Benchmark Datasets for Machine Learning Tasks related to Natural
                  Disasters. MIT IEEE Undergraduate Research Technology Conference, September 2022, Cambridge, MA.
                </Text>
                <div style={grid_1f_2f_4f}>
                  <Button
                    onClick={() => openReference(poster)}
                    bg="#7AAC35" color="#FFFFFF" variant="solid" mb="10px">
                    View
                  </Button>
                  <Button bg="#7AAC35" color="#FFFFFF" variant="solid" mb="10px" onClick={displayPosterToast}>
                    Copy Reference
                  </Button>
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </div>


      {/* ACKNOWLEDGMENTS*/}
      <div>
        <div>
          <Flex marginTop={"60px"} marginBottom={"15px"}>
            <Text className='about_header'>
              Acknowledgments
            </Text>
          </Flex>
        </div>

        <Text
          mb="30px"
          className='about_text'
          paddingTop={"10px"}
        >
          <p> Adiba Proma</p>
          <p> Stela Ciko </p>
          <p> Duong Phan, Danni Shi, Bao Tran, Yufei Li</p>
        </Text>
      </div>

    </Box>
  );
};

export default About;
