import React, { useEffect, useState } from 'react';
import {
  Box,
  Image,
  Text,
  Button,
  Flex,
  Spacer,
  SimpleGrid, Divider,
  Input, IconButton,
} from '@chakra-ui/react';
import { getApprovedDatasets, getMainImageURL } from './firebase_functions';
import { SearchIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Filter from '../components/Filter';

const DatasetList = () => {

  const [initialList, setInitialList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainImage, setMainImage] = useState('');
  const [datasetList, setDatasetList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  //toggles between t and f, no inherent meaning, used by Filter to detect search
  const [calledHandleSearch, setCalledHandleSearch] = useState(true);


  //GET APPROVED DATASETS
  useEffect(() => {
    getApprovedDatasets(setDatasetList, setInitialList);
    // console.log("datasetList: ", datasetList);
    // console.log("initialList: ", initialList);
  }, []);

  const handleSearchInput = e => {
    e.preventDefault();
    setSearchInput(e.target.value);
    // console.log("searchInput: ",searchInput)
  };

  // SEARCH: clears filters then searches
  const handleSearch = (onApplyFilters = false) => {
    const searchResults = [];
    //reset filters
    if (!onApplyFilters) {
      setCalledHandleSearch(!calledHandleSearch);
      // console.log(" setting calledSearch")
    }
    else {
      // console.log("not setting calledSearch")
    }

    initialList.forEach(dataset => {
      // console.log(dataset);
      if (
        dataset.paper_title.toLowerCase().match(searchInput.toLowerCase()) ||
        dataset.phases.toLowerCase().match(searchInput.toLowerCase()) ||
        dataset.task_type_str.toLowerCase().match(searchInput.toLowerCase()) ||
        dataset.description.toLowerCase().match(searchInput.toLowerCase())
      ) {
        searchResults.push(dataset);
      }
    });
    if (searchResults.length > 0) {
      setDatasetList(searchResults);
    }
    else {
      // console.log("no search matched")
      setDatasetList([])
    }
  };

  const handleFilter = (data) => {
    if (data.length > 0) {
      setDatasetList(data);
    } else {
      setDatasetList([])
    }
  };

  const getMainImage = (filename) => {
    return (getMainImageURL(filename));
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <Box
      paddingLeft={{ base: '50px', '2xl': "200px" }}
      paddingRight={{ base: '50px', '2xl': "200px" }}
    >
      {/* <InputGroup> */}
      <Flex
        justifyContent="center"
        alignItems="center"
        paddingTop={"35px"}
      >
        <Input
          placeholder="Search..."
          type="search"
          onChange={handleSearchInput}
          value={searchInput}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          w="50vw"
          focusBorderColor="#7AAC35"
          // focusBorderWidth="1px"
          _hover={{ borderColor: "#7AAC35" }}
          borderColor={"#7AAC35"}
          borderLeftRadius={"3xl"}
          borderRightRadius={"0px"}
          borderWidth="1px"
        />

        {/* <InputRightElement position="end"> */}
        <IconButton
          aria-label="Search"
          icon={<SearchIcon />}
          onClick={handleSearch}
          borderRightColor="#7AAC35"
          borderTopColor="#7AAC35"
          borderBottomColor="#7AAC35"
          borderRightRadius={"3xl"}
          borderLeftRadius={"0px"}
          borderWidth="1px"
          boxShadow="none"

        />
      </Flex>

      <SimpleGrid templateColumns="1fr 4fr" columns={2}>
        {/* Filters */}
        <Filter datasets={datasetList} onFilter={handleFilter} onClear={handleSearch} calledSearch={calledHandleSearch} />

        {/* Dataset List */}
        <SimpleGrid mt="50px"
          columns={2}
          spacingX={{ base: 3, '2xl': 2 }}
          spacingY={{ base: 6, '2xl': 7 }}
        >
          {/* {console.log("datasetList before mapping:", datasetList)}
          {console.log("initialLIst before mapping:", initialList)} */}

          {datasetList.map((dataset, index) => (
            <Box
              maxW={"500px"}
              height={"370px"}
              borderWidth="1px"
              borderRadius="lg"
              overflow="scroll"
              boxShadow="md"
              pr="30px"
              pl="30px"
              pt="25px"
              key={index}
            >
              <Flex mb="20px">
                <Image
                  src={dataset.image_url}
                  alt="Image not Available"
                  border={"1px"}
                  borderColor={'lightgray'}
                  w="140px"
                  h="130px"
                  mr="20px"
                />
                <Spacer />
                <Box>
                  <Text fontSize="xl" as="b">
                    {dataset.paper_title}
                  </Text>
                  <Spacer />
                  <Text className='dd_text'>
                    {dataset.data_type} | {dataset.topic} | {dataset.phases}
                  </Text>
                </Box>
              </Flex>
              <Text fontSize="sm" mb="20px">
                {dataset.description}
              </Text>
              <Flex>
                <Text fontSize="sm" mt="8px">
                  {/* <Dash_separated arr={dataset.task_type} /> */}
                  <b>ML Task Type:</b> {dataset.task_type_str}
                </Text>
                <Spacer />
                <Link to={`detail/${dataset.slug}`} state={dataset} onClick={scrollToTop}>
                  <Button bg="#7AAC35" color="#FFFFFF" variant="solid" mb="10px">
                    Learn More
                  </Button>
                </Link>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
};

export default DatasetList;
