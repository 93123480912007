import {
  Box,
  Text,
  Image,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import contactImg from '../img/contact.png';
import { submitContact } from '../pages/firebase_functions';

const GeneralForm_Contact = () => {
  const handleInputChange = e => {
    const { name, value } = e.target;
    setContactInput(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [contactInput, setContactInput] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    timestamp: '',
  });

  const toast = useToast();

  const submitContactInfo = async () => {

    if (contactInput.name == '' || contactInput.email == '' || contactInput.message == '') {
      toast({
        title: 'Error',
        description: 'Please fill out all required fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    else {
      const res = submitContact(contactInput);
      // console.log(res);
      res.then(() => {
        // Success: The dataset was submitted successfully
        // console.log('Message delivered');
        toast({
          title: 'Success',
          description: 'Message delivered!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setContactInput({
          name: '',
          email: '',
          subject: '',
          message: '',
          timestamp: '',
        });
      }).catch((error) => {
        // console.log('Error submitting contact:', error);
        toast({
          title: 'Error',
          description: 'Unable to deliver message.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    }
  };

  return (
    <Box 
      mt={{ base: '20px', '2xl': "70px" }}
      paddingTop={"50px"}
      paddingLeft={{base: '50px', '2xl': "200px"}}
      paddingRight={{base: '50px', '2xl': "200px"}}
      >
      <Flex mt="30px" w="70%">
        <Image
          src={contactImg}
          ml="40px"
          w="500px"
          mb="300px"
          mt="10px"
          mr={{ base: '150px', '2xl': "250px" }}
        />

        <Box mt="-20px" mr="30px">
          <FormControl isRequired mb="20px">
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              value={contactInput?.name}
              onChange={handleInputChange}
              name="name"
            />
          </FormControl>
          <FormControl isRequired mb="20px">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={contactInput?.email}
              onChange={handleInputChange}
              name="email"
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Subject</FormLabel>
            <Input
              type="text"
              value={contactInput?.subject}
              onChange={handleInputChange}
              name="subject"
            />
          </FormControl>
          <FormControl isRequired mb="20px">
            <FormLabel>Message</FormLabel>
            <Textarea
              type="text"
              value={contactInput?.message}
              onChange={handleInputChange}
              w="500px"
              h="200px"
              name="message"
            />
          </FormControl>
          <Button
            bg="#7AAC35"
            color="#FFFFFF"
            variant="solid"
            mt="30px"
            ml="200px"
            size="lg"
            onClick={submitContactInfo}
          >
            Submit
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default GeneralForm_Contact;
